import { makeStyles } from "@material-ui/core/styles";



    
const useStyles = makeStyles((theme) => ({
    modal: {
      position: "absolute",
      width: "40%",
      fontFamily: "Poppins",
      
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      '@media (max-width: 780px)' : {
        width: '90%'
      },
    },
  
     
    iconos: {
      cursor: "pointer",
    },
    inputMaterial: {
      width: "100%",
      fontFamily: "Poppins",
    },
    formControl: {
      minWidth: 120,
      marginTop: "10px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },


    titulo: {
      textAlign: "center",
      fontSize: "28px",
      fontFamily: "Poppins",
    },

    subtitulo: {
      textAlign: "left",
      fontSize: "20px",
      marginTop: "30px",
      fontFamily: "Poppins",
    },
    margincosto: {
      marginTop: "24px",
    },

    margintramite: {
      marginBottom: "34px",
    },
  
  
  
  }));




export default useStyles ;