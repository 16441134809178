import React, { Fragment, forwardRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../App.css";
import { makeStyles } from "@material-ui/core/styles";
import NavigationAdministrador from "../Navigation/NavigationAdministrador";
import { TextField, Button } from "@material-ui/core";
import "../../css/custom.css";
import constants from "../constantes";
import FormControl from "@mui/material/FormControl";
import { FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import estilosForm from "../Formularios/FormulariosStyle";
import { useFormik } from "formik";
import * as yup from "yup";
import imagenes from "../imagenes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SendIcon from "@mui/icons-material/Send";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import "./../../App.css";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { Apps } from "@material-ui/icons";
import Footer from "../Navigation/Footer";
import ReactGA from 'react-ga';

const re = /^[0-9\b]+$/;

const Cuestionario = () => {
  const MySwal = withReactContent(Swal);
  const styles = estilosForm();

  const TRACKING_ID = "G-E48CGYC83D";
  ReactGA.initialize(TRACKING_ID);

  const validationSchema = yup.object({
    Therapy: yup.string().required("La terapia es requerida"),
    PrescriptionType: yup.string().required("El tipo de cita es requerida"),
  });

  const formik = useFormik({
    initialValues: {
      Therapy: "",
      PrescriptionType: "",
      InhalacionesR: 1,
      PeriodsR: 0,
      InhalacionesM: 1,
      PeriodoM: 1,
      PeriodsM: 0,
      

    },
    onSubmit: (values) => {
      console.log("si entra aqui");
      setPosition(3)

      let consulta = constants.api + "salbutamol/calc";

      const requestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };

      console.log(consulta)
      console.log(requestInit)

      let consultaposition = constants.api + "salbutamol/position";

      const requestPosition = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(objposition),
      };

    fetch(consultaposition, requestPosition)
    .then((res) =>  res.json() )
    .then((res) => {

      console.log(res);

    }).catch(err => {

      console.log(err)
    });

       fetch(consulta, requestInit)
        .then((res) =>  res.json() )
        .then((res) => {
          localStorage.setItem("umbral", res.umbral);
          localStorage.setItem("canistiermonth", res.canistersPerMonth);
          localStorage.setItem("inalacionesmoth", res.inhalationsPerMonth);
          localStorage.setItem("canistieryear", res.canistersPerYear);
          localStorage.setItem("tipoterapia", tipoterapia);
         

          let redirect = () => {
            window.location.href = "/resultados";
          };

          if (res) {
            redirect();
          } else {
            console.log("no hay respuesta");
          }

          console.log(res);
          console.log(res.umbral);
        }).catch(err => {

          console.log(err)
        }); 

      console.log(JSON.stringify(values));
    },

    validationSchema: validationSchema,
  });

  const [text, setText] = useState("");
  const [rescate, setRescate] = useState(false);
  const [mantenimiento, setMantenimiento] = useState(false);
  const [ambas, setAmbas] = useState(false);

  const [position, setPosition] = useState(0);

  const [tipoterapia, setTipoTerapia] = useState(0);

  console.log(position)

  let username = localStorage.getItem("username");
  let substr = localStorage.getItem("sub");

  const objposition = {

    position: position,
    user: username,
    token: substr

  }


  const handleChangeHospitalType = (event) => {
    setText(event.target.value);
    setPosition(1)

    const requestPosition = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(objposition),
    };

    let consulta = constants.api + "salbutamol/position";

    fetch(consulta, requestPosition)
    .then((res) =>  res.json() )
    .then((res) => {

      console.log(res);

    }).catch(err => {

      console.log(err)
    }); 

    
      
    let hospitaltype = event.target.value;
    console.log(event.target.value);
    formik.values.PrescriptionType = hospitaltype;
  };

  const handleChangeTherapy = (event) => {
    setText(event.target.value);
    let therapytype = parseInt(event.target.value);
    setPosition(2)
    console.log(therapytype);
    console.log(tipoterapia + "este es");
    formik.values.Therapy = therapytype;
    if (therapytype == 0) {
      console.log("escogio terapia de rescate");

      setRescate(true);
      setMantenimiento(false);
      setAmbas(false);
      setTipoTerapia(therapytype)
    } else if (therapytype == 1) {
      console.log("escogio terapia de mantenimiento");
      setRescate(false);
      setMantenimiento(true);
      setAmbas(false);
      setTipoTerapia(therapytype)
    } else {
      console.log("escogio ambas");
      setRescate(false);
      setMantenimiento(false);
      setAmbas(true);
      setTipoTerapia(therapytype)
    }

    const requestPosition = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(objposition),
    };

    let consulta = constants.api + "salbutamol/position";

    fetch(consulta, requestPosition)
    .then((res) =>  res.json() )
    .then((res) => {

      console.log(res);

    }).catch(err => {

      console.log(err)
    });

    
  };

  return (
    <Fragment>
      <NavigationAdministrador></NavigationAdministrador>
      <form onSubmit={formik.handleSubmit}>
      <div className="fondo-cuestionario">

      
        <div className="container-fluid ">
      
          <div className="row ">
            <div className="col-lg-12">
              <br></br>
              <br></br>

              <br></br>

             
                <div className="row ">

                <div className="col-lg-12">
                  <h1 className="bienve">Bienvenido a Calculadora de Riesgo</h1><br></br>
                </div>


                  <div className="col-lg-7 ">

                  <p className="textoaz">
                    “Estimado Doctor, sabemos la importancia de evaluar el
                    tratamiento de su paciente y su posible sobreuso de
                    agonistas β2 de acción corta; por ello podrá utilizar esta
                    calculadora como apoyo a la valoración de su paciente y su
                    tratamiento”.<br></br>
                    <br></br> Siga estos pasos para ingresar: <br></br>
                    <br></br>
                    <li>
                      {" "}
                      De clic en “Consulta” para iniciar<br></br>
                    </li>
                    <li>
                      {" "}
                      Seleccionar la manera en la que suele recetar Salbutamol a
                      sus pacientes<br></br>
                    </li>
                    <li>
                      {" "}
                      Seleccionar si suele recetar Salbutamol como rescate,
                      mantenimiento o <br></br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      ambas<br></br>
                    </li>
                    <li>
                      Contestar con el número de inhalaciones por la cantidad de
                      tiempo que suele <br></br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      recetar a su paciente<br></br>
                    </li>
                    <li>
                      {" "}
                      De clic en “Enviar”<br></br>
                      <br></br>
                    </li>
                    Al finalizar le mostrara alguno de estos 4 posibles
                    resultados para a partir de ahí evalué el tratamiento de su
                    paciente:<br></br>
                    <br></br>
                    <img className="imagenesr" src={imagenes.resulta}></img>
                  </p><br></br>
                  </div>

                  <div className="col-lg-5 col-xs-12 "><br></br><br></br>
                    <div className="fondo-blanco">
                      <div className="titulo">
                        Herramienta para evaluar riesgos
                      </div>
                      <p className="subtittle">
                        Lo invitamos a evaluar si su paciente se encuentra en <br></br> un
                        potencial riesgo por sobreuso de SALBUTAMOL
                      </p>
                      <br></br>

                      <p className="pregunta1">
                        <i>
                          *¿Cómo receta el Salbutamol en sus pacientes con asma?
                        </i>
                      </p>
                      <FormControl fullWidth margin="normal">
                        <InputLabel
                          className="selectstyle"
                          id="demo-simple-select-helper-label"
                        >
                          Tipo de receta
                        </InputLabel>
                        <Select
                          className="selectstyle"
                          id="PrescriptionType"
                          InputLabelProps={{
                            style: { fontSize: 14, fontFamily: "monospace" },
                          }}
                          name="PrescriptionType"
                          label="Tipo de receta"
                          value={formik.values.PrescriptionType}
                          onChange={(e) => handleChangeHospitalType(e)}
                          error={
                            formik.touched.PrescriptionType &&
                            Boolean(formik.errors.PrescriptionType)
                          }
                          helperText={
                            formik.touched.PrescriptionType &&
                            formik.errors.PrescriptionType
                          }
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem className="inputform" value={0}>
                            Solo, como monoterapia
                          </MenuItem>
                          <MenuItem className="inputform" value={1}>
                            En combinación con ICS
                          </MenuItem>
                          <MenuItem className="inputform" value={2}>
                            En combinación con otro broncodilatador
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {formik.touched.PrescriptionType &&
                            formik.errors.PrescriptionType}
                        </FormHelperText>
                      </FormControl>

                      <br></br>
                      <br></br>
                      <br></br>
                      <p className="pregunta1">
                        <i>*¿Para qué tipo de terapia utiliza el salbutamol?</i>
                      </p>

                      <FormControl>
                        <RadioGroup
                          row
                          id="Therapy"
                          name="Therapy"
                          value={formik.values.Therapy}
                          onChange={(e) => handleChangeTherapy(e)}
                          error={
                            formik.touched.Therapy &&
                            Boolean(formik.errors.Therapy)
                          }
                          onBlur={formik.handleBlur}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Terapia de Rescate"
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Terapia de Mantenimiento"
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Ambas"
                          />
                        </RadioGroup>

                        <FormHelperText>
                          {formik.touched.CustomerType &&
                            formik.errors.CustomerType}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                </div>
              
            </div>
            
          </div>
        </div>
      
      </div>
      {rescate && (
        <>
          <div className="fondo-gris">
            <div className="row ">
              <div className="col-lg-12 col-sm-12 justify-content-center">
                <div className="centrado">


                  <p className="pregunta2">
                    Suelo recetar &nbsp;
                    <span className="margin">
                      <TextField
                        className="inalaciones"
                        id="InhalacionesR"
                        name="InhalacionesR"
                        label=""
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: { 
                              max: 100, min: 1 
                          }
                        }}
                        value={formik.values.InhalacionesR}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.InhalacionesR &&
                          Boolean(formik.errors.InhalacionesR)
                        }
                        helperText={
                          formik.touched.InhalacionesR &&
                          formik.errors.InhalacionesR
                        }
                        onBlur={formik.handleBlur}
                      />
                    </span>
                    &nbsp; inhalaciones al &nbsp;
                    <span className="margin">
                      <FormControl className="frecuencia">
                        <Select
                          className="frecuencia"
                          id="PeriodsR"
                          name="PeriodsR"
                          label=""
                          value={formik.values.PeriodsR}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.PeriodsR &&
                            Boolean(formik.errors.PeriodsR)
                          }
                          helperText={
                            formik.touched.PeriodsR && formik.errors.PeriodsR
                          }
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem className="inputform" value={0}>
                            Día
                          </MenuItem>
                          <MenuItem className="inputform" value={1}>
                            Semana
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {formik.touched.Periods && formik.errors.Periods}
                        </FormHelperText>
                      </FormControl>
                    </span>
                    &nbsp; por un mes como terapia de rescate para mis pacientes
                  </p>

                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {mantenimiento && (
        <>
          <div className="fondo-gris">
            <div className="row ">
              <div className="col-lg-12 justify-content-center">
                <div className="centrado">
                  <p className="pregunta2">
                    Suelo recetar &nbsp;
                    <span className="margin">
                      <TextField
                        className="inalaciones"
                        id="InhalacionesM"
                        name="InhalacionesM"
                        label=""
                        variant="outlined"
                        type="number"
                        value={formik.values.InhalacionesM}
                        onChange={formik.handleChange}
                        InputProps={{
                          inputProps: { 
                              max: 100, min: 1 
                          }
                        }}
                        error={
                          formik.touched.InhalacionesM &&
                          Boolean(formik.errors.InhalacionesM)
                        }
                        helperText={
                          formik.touched.InhalacionesM &&
                          formik.errors.InhalacionesM
                        }
                        onBlur={formik.handleBlur}
                      />
                    </span>
                    &nbsp; inhalaciones al día por un periodo de &nbsp;
                    <span className="margin">
                      <TextField
                        className="inalaciones"
                        id="PeriodoM"
                        name="PeriodoM"
                        label=""
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: { 
                              max: 100, min: 1 
                          }
                        }}
                        value={formik.values.PeriodoM}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.PeriodoM &&
                          Boolean(formik.errors.PeriodoM)
                        }
                        helperText={
                          formik.touched.PeriodoM && formik.errors.PeriodoM
                        }
                        onBlur={formik.handleBlur}
                      />
                    </span>
                    &nbsp;
                    <span className="margin">
                      <FormControl className="frecuencia">
                        <Select
                          className="frecuencia"
                          id="PeriodsM"
                          name="PeriodsM"
                          label=""
                          
                          value={formik.values.PeriodsM}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.PeriodsM &&
                            Boolean(formik.errors.PeriodsM)
                          }
                          helperText={
                            formik.touched.PeriodsM && formik.errors.PeriodsM
                          }
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem className="inputform" value={0}>
                            Semanas
                          </MenuItem>
                          <MenuItem className="inputform" value={1}>
                            Meses
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {formik.touched.Periods && formik.errors.Periods}
                        </FormHelperText>
                      </FormControl>
                    </span>{" "}
                    &nbsp; como terapia de mantenimiento para mis pacientes
                  </p>


                </div>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </>
      )}

      {ambas && (
        <>
          <div className="fondo-gris">
            <div className="row ">
              <div className="col-lg-12 justify-content-center">
                <div className="centrado">

                
                <p className="pregunta2">
                    Suelo recetar &nbsp;
                    <span className="margin">
                      <TextField
                        className="inalaciones"
                        id="InhalacionesR"
                        name="InhalacionesR"
                        label=""
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: { 
                              max: 100, min: 1 
                          }
                        }}
                        value={formik.values.InhalacionesR}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.InhalacionesR &&
                          Boolean(formik.errors.InhalacionesR)
                        }
                        helperText={
                          formik.touched.InhalacionesR &&
                          formik.errors.InhalacionesR
                        }
                        onBlur={formik.handleBlur}
                      />
                    </span>
                    &nbsp; inhalaciones al &nbsp;
                    <span className="margin">
                      <FormControl className="frecuencia">
                        <Select
                          className="frecuencia"
                          id="PeriodsR"
                          name="PeriodsR"
                          label=""
                          value={formik.values.PeriodsR}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.PeriodsR &&
                            Boolean(formik.errors.PeriodsR)
                          }
                          helperText={
                            formik.touched.PeriodsR && formik.errors.PeriodsR
                          }
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem className="inputform" value={0}>
                            Día
                          </MenuItem>
                          <MenuItem className="inputform" value={1}>
                            Semana
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {formik.touched.Periods && formik.errors.Periods}
                        </FormHelperText>
                      </FormControl>
                    </span>
                    &nbsp; por un mes como terapia de rescate para mis pacientes
                  </p>


                </div>
              </div>
            </div>
            <br></br>
            <br></br>
        </div>
        <div className="fondo-gris">
            <div className="row ">
              <div className="col-lg-12 justify-content-center">
                <div className="centrado">

                           <p className="pregunta2">
                    Suelo recetar &nbsp;
                    <span className="margin">
                      <TextField
                        className="inalaciones"
                        id="InhalacionesM"
                        name="InhalacionesM"
                        label=""
                        variant="outlined"
                        type="number"
                        value={formik.values.InhalacionesM}
                        onChange={formik.handleChange}
                        InputProps={{
                          inputProps: { 
                              max: 100, min: 1 
                          }
                        }}
                        error={
                          formik.touched.InhalacionesM &&
                          Boolean(formik.errors.InhalacionesM)
                        }
                        helperText={
                          formik.touched.InhalacionesM &&
                          formik.errors.InhalacionesM
                        }
                        onBlur={formik.handleBlur}
                      />
                    </span>
                    &nbsp; inhalaciones al día por un periodo de &nbsp;
                    <span className="margin">
                      <TextField
                        className="inalaciones"
                        id="PeriodoM"
                        name="PeriodoM"
                        label=""
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: { 
                              max: 100, min: 1 
                          }
                        }}
                        value={formik.values.PeriodoM}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.PeriodoM &&
                          Boolean(formik.errors.PeriodoM)
                        }
                        helperText={
                          formik.touched.PeriodoM && formik.errors.PeriodoM
                        }
                        onBlur={formik.handleBlur}
                      />
                    </span>
                    &nbsp;
                    <span className="margin">
                      <FormControl className="frecuencia">
                        <Select
                          className="frecuencia"
                          id="PeriodsM"
                          name="PeriodsM"
                          label=""
                          
                          value={formik.values.PeriodsM}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.PeriodsM &&
                            Boolean(formik.errors.PeriodsM)
                          }
                          helperText={
                            formik.touched.PeriodsM && formik.errors.PeriodsM
                          }
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem className="inputform" value={0}>
                            Semanas
                          </MenuItem>
                          <MenuItem className="inputform" value={1}>
                            Meses
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {formik.touched.PeriodsM && formik.errors.PeriodsM}
                        </FormHelperText>
                      </FormControl>
                    </span>{" "}
                    &nbsp; como terapia de mantenimiento para mis pacientes
                  </p>


                </div>
              </div>
            </div>
            <br></br>
            <br></br>
          </div>
        </>
      )}

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3">
            <br></br>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              endIcon={<SendIcon />}
              className="boton-amarillo"
            >
              Enviar
            </Button>
            
          </div>
        </div>
      </div>
      </form>                     
      <br></br>
      <br></br>
      <Footer></Footer>
    </Fragment>
  );
};

// Encabezados table

export default Cuestionario;
