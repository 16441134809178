import React, { Fragment, forwardRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../../App.css";

import NavigationAdministrador from "../Navigation/NavigationAdministrador";
import { TextField, Button } from "@material-ui/core";
import "../../css/custom.css";
import constants from "../constantes";
import estilosForm from "../Formularios/FormulariosStyle";

import imagenes from "../imagenes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Footer from "../Navigation/Footer";

const re = /^[0-9\b]+$/;

const Estadisticas = () => {
  const MySwal = withReactContent(Swal);
  const styles = estilosForm();

  let umbrald = "1";

  switch (umbrald) {
    case "1":
      return (
        <Fragment>
          <NavigationAdministrador></NavigationAdministrador>

          <div className="fondo-principal">

            <div className="container-fluid ">
              <div className="row justify-content-center imagenesr">
                <div className="col-lg-12">
                  <h1 className="bienve">Bienvenido a Calculadora de Riesgo</h1>
                </div>
                <div className="col-lg-6">
                  <br></br>
                  <p className="textoaz">
                    “Estimado Doctor, sabemos la importancia de evaluar el
                    tratamiento de su paciente y su posible sobreuso de
                    agonistas β2 de acción corta; por ello podrá utilizar esta
                    calculadora como apoyo a la valoración de su paciente y su
                    tratamiento”.<br></br>
                    <br></br> Siga estos pasos para ingresar: <br></br>
                    <br></br>
                    <li>
                      {" "}
                      De clic en “Consulta” para iniciar<br></br>
                    </li>
                    <li>
                      {" "}
                      Seleccionar la manera en la que suele recetar Salbutamol a
                      sus pacientes<br></br>
                    </li>
                    <li>
                      {" "}
                      Seleccionar si suele recetar Salbutamol como rescate,
                      mantenimiento o <br></br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      ambas<br></br>
                    </li>
                    <li>
                      Contestar con el número de inhalaciones por la cantidad de
                      tiempo que suele <br></br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      recetar a su paciente<br></br>
                    </li>
                    <li>
                      {" "}
                      De clic en “Enviar”<br></br>
                      <br></br>
                    </li>
                    Al finalizar le mostrara alguno de estos 4 posibles
                    resultados para a partir de ahí evalué el tratamiento de su
                    paciente:<br></br>
                    <br></br>
                    <img className="imagenesr" src={imagenes.resulta}></img>
                  </p>
                </div>

                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <br></br>
                      <br></br>
                      <Link to="/graficas">
                        <img className="imagenesr" src={imagenes.graficas} />
                      </Link>
                      {/* <div className="txtestadisticas">Gráficas</div> */}
                    </div>
                    <div className="col-lg-6 ">
                      <br></br>
                      <br></br>
                      <Link to="/cuestionario">
                        <img className="imagenesr" src={imagenes.consulta} />
                      </Link>
                      {/* <div className="txtestadisticas">Consultas</div>  */}
                    </div>

                    <div className="botonmorado">Da click para <br></br> iniciar</div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>

          <Footer></Footer>
        </Fragment>
      );

      break;

    default:
      break;
  }
};

// Encabezados table

export default Estadisticas;
