import React, {Fragment,} from 'react';
import "bootstrap/dist/css/bootstrap.css"
import CircularProgress from '@material-ui/core/CircularProgress';



const Loading = () => {

    
    return(

        <Fragment>

           <div className="container " >
             <div className="row  justify-content-center">
                 <div className="col-lg-2"><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

                 <CircularProgress className="spinner" />
          
                </div>
            </div>
           </div>

        </Fragment>
        
    )
}

export default Loading;