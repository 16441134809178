import React, { Fragment, forwardRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../App.css";
import { makeStyles } from "@material-ui/core/styles";
import NavigationAdministrador from "../Navigation/NavigationAdministrador";
import { TextField, Button } from "@material-ui/core";
import "../../css/custom.css";
import constants from "../constantes";
import FormControl from "@mui/material/FormControl";
import { FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import estilosForm from "../Formularios/FormulariosStyle";
import { useFormik } from "formik";
import * as yup from "yup";
import imagenes from "../imagenes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Footer from "../Navigation/Footer";
import SendIcon from "@mui/icons-material/Send";
import { Link } from 'react-router-dom';



const re = /^[0-9\b]+$/;

const Resultados = () => {
  const MySwal = withReactContent(Swal);
  const styles = estilosForm();

  let umbrald = localStorage.getItem("umbral");
  let canistiersd = localStorage.getItem("canistiermonth");
  let inhalacionesd = localStorage.getItem("inalacionesmoth");
  let tipoterapia = localStorage.getItem("tipoterapia");
  let canistiersyear = localStorage.getItem("canistieryear");
  


  canistiersd = Math.ceil(canistiersd)

  canistiersyear = Math.ceil(canistiersyear)

  let titular 

  if (tipoterapia == 0) {
    console.log("escogio terapia de rescate");

   titular = "Tus resultados para el tratamiento de SABA como Terapia de Rescate son los siguientes"
  } else if (tipoterapia == 1) {
    console.log("escogio terapia de mantenimiento");

    titular = "Tus resultados para el tratamiento de SABA como Terapia de Mantimiento son los siguientes"

    canistiersd = canistiersyear

  } else {
    console.log("escogio ambas");

    canistiersd = canistiersyear

    titular = "Tus resultados para el tratamiento de SABA como Terapia de Mantimiento y Rescate son los siguientes"

  }

  

  switch (umbrald) {
    case "0":
      return (
        <Fragment>
          <NavigationAdministrador></NavigationAdministrador>
          <div className="container ">
            <div className="row  justify-content-center">
           <div className="col-lg-12">
                <br></br>
                <br></br>
                <div className="titulo">
                {titular}
                </div>
                <br></br>
                <br></br>

                <br></br>
                <br></br>
              </div>
              <div className="col-lg-6">
                <br></br>
                <br></br>

                

                

                <div className="tituloressucces">
                  <img src={imagenes.verde}></img>
                  <br></br>
                 Libre de riesgo
                </div>

                <br></br>
              </div>
            </div>
          </div>
        </Fragment>
      );

      break;
    case "1":
      return (
        <Fragment>
          <NavigationAdministrador></NavigationAdministrador>
          <div className="container ">
            <div className="row  justify-content-center">
              <div className="col-lg-12">
                <br></br>
                <br></br>
                <div className="titulo">
                {titular}
                </div>
                <br></br>
                <br></br>

                <br></br>
                <br></br>
              </div>
              <div className="col-lg-6">
                <div className="tituloresamarillo">
                  <img src={imagenes.amarillo}></img>
                  <br></br>
                  3+ inhalaciones por semana aumenta riesgo de exacerbaciones
                </div>
              </div>

              <div className="col-lg-6">
                <br></br>
                <br></br>

            
                  <img className="canisters" src={imagenes.canister}></img>

                  <div className="numeroc">{canistiersd}</div>

                  <br></br>
            <div className="boton-regresar">
                           <Link to="/"  className="white2">Regresar</Link>
            </div>
            
             
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Footer></Footer>
        </Fragment>
      );

      break;

    case "2":
      return (
        <Fragment>
          <NavigationAdministrador></NavigationAdministrador>
          <div className="container ">
            <div className="row  justify-content-center">
              <div className="col-lg-12">
                <br></br>
                <br></br>
                <div className="titulo">
                {titular}
                </div>
                <br></br>
                <br></br>
              </div>
              <div className="col-lg-6">
                <br></br>
                <br></br>

                <div className="tituloresazul">
                  <img src={imagenes.azul}></img>
                  <br></br>
                  3+ dispositivos por año duplica probabilidades de hospitalizacion
                  <br></br>
                </div>
              </div>

              <div className="col-lg-6">
                <br></br>
                <br></br>

            
                  <img className="canisters" src={imagenes.canister}></img>

                  <div className="numeroc">{canistiersd}</div>

                  <Button
              variant="contained"
              type="submit"
              fullWidth
              endIcon={<SendIcon />}
              className="boton-amarillo"
            >
              Enviar
            </Button>
            
             
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Footer></Footer>
        </Fragment>
      );

      break;

    case "3":
      return (
        <Fragment>
          <NavigationAdministrador></NavigationAdministrador>
          <div className="container ">
            <div className="row  justify-content-center">
              <div className="col-lg-12">
                <br></br>
                <br></br>
                <div className="titulo">
                    {titular}
                </div>


{/*                <div className="centrado">
                  <p className="pregunta2">
                    Número de canastiers usados &nbsp;
                    <span className="margin">
                      <TextField
                        className="inalaciones"
                        id="InhalacionesR"
                        name="InhalacionesR"
                        label=""
                        variant="outlined"
                        type="number"
                        disabled="true"
                        value={canistiersd}
                      />
                    </span>
                    &nbsp; y un total de &nbsp;
                    <span className="margin">
                      <TextField
                        className="inalaciones"
                        id="InhalacionesR"
                        name="InhalacionesR"
                        label=""
                        variant="outlined"
                        type="number"
                        disabled="true"
                        value={inhalacionesd}
                      />
                    </span>
                    &nbsp; inhalaciones por un mes como terapia de rescate para
                    mis pacientes
                  </p>
                </div>
  */}

              </div>
              <div className="col-lg-6 col-xs-10">
                <br></br>
                <br></br>

                <div className="tituloresrojo">
                  <img src={imagenes.rojo}></img>
                  <br></br>
                  12+ dispositivos por año aumenta riesgo de muerte
                  <br></br>
                </div>
              </div>

              <div className="col-lg-6">
                <br></br>
                <br></br>

            
                  <img className="canisters" src={imagenes.canister}></img>

                  <div className="numeroc">{canistiersd}</div>
             
              </div>
            </div>
          </div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Footer></Footer>
        </Fragment>
      );

      break;

    default:
      break;
  }
};

// Encabezados table

export default Resultados;
