import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import "../../App.css";
import imagenes from "../imagenes";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const Footer = () => {

  

  let username = localStorage.getItem("username");

  return (
    <>
      <footer className=" text-lg-start text-muted black2">
        <section className="">
          <div className="container text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-12 col-lg-4 col-xl-4 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3"></i>AZMED de AstraZeneca
                </h6>
                Uso exclusivo para Profesionales de la salud.Si usted no es un
                profesional de la salud, por favor diríjase aquí: 
                <a
                  href="https://www.astrazeneca.mx/"
                  target="_blank"
                  className="whitea"
                >
                  www.astrazeneca.com.mx
                </a>
                <br></br>
                <br></br>© AstraZeneca 2018. Todos los derechos reservados.
                Fecha de aprobación interna: Agosto 2020 No. De ITEM: 114685
                Aviso de Publicidad No. 203300202C3732
              </div>

              <div className="col-md-12 col-lg-4 col-xl-4 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Products</h6>
                <a
                  href="https://www.astrazeneca.mx/politicadeprivacidad.html"
                  target="_blank"
                  className="whitea"
                >
                  Notificación legal y términos de uso
                </a>
                <br></br>
                <a
                  href="https://www.astrazeneca.mx/politicadeprivacidad.html"
                  target="_blank"
                  className="whitea"
                >
                  Política de Privacidad
                </a>
                <br></br>
                <a
                  href="https://www.azmed.com.mx/notificacion-sobre-cookies.html"
                  target="_blank"
                  className="whitea"
                >
                  Notificación sobre cookies
                </a>
              </div>

              <div className="col-md-12 col-lg-4 col-xl-4 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <div className="bordeado">
                  {" "}
                  <a
                    href="https://www.azmed.com.mx/inicio/contactenos.html"
                    target="_blank"
                    className="whitea"
                  >
                      Contáctenos
                  </a>
                  
                </div>
                <div className="bordeado">
                  <a
                    href="https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/mx/es/amp-form.html"
                    target="_blank"
                    className="whitea"
                  >
                    Notificación de reacciones adversas
                  </a>
                </div>
                <div className="bordeado">
                  <a
                    href="https://www.facebook.com/astrazenecamx"
                    target="_blank"
                    className="whitea"
                  >
                    Síganos en facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
