
import React, {Fragment} from 'react';
import 'semantic-ui-css/semantic.min.css'
import 'bootstrap/dist/css/bootstrap-grid.css'
import './App.css'

import Login from './Components/Login'

import { BrowserRouter as Router, Route } from 'react-router-dom';
import TagManager from "react-gtm-module";



import ValidacionVistas from './Components/Vistas/ValidacionVistas';
import ValidacionAlta from './Components/Vistas/ValidacionAlta';
import Loading from './Components/Loading';
import RepresentanteMedico from './Components/Vistas/Representante-Medico';
import RegistroAdministrativo from './Components/Vistas/Cuestionario';
import Administrador from './Components/Vistas/Administrador';

import ValidacionVistasFirst from './Components/Vistas/ValidacionVistasFirst';

import Principal from './Components/Vistas/Principal';
import Resultados from './Components/Vistas/Resultados';
import Cuestionario from './Components/Vistas/Cuestionario';
import Estadisticas from './Components/Vistas/Estadisticas';
import Graficas from './Components/Vistas/Graficas';
import Consultas from './Components/Vistas/Administrador';



const tagManagerArgs = {
  gtmId: 'G-E48CGYC83D'
  };


const App = () => {

  TagManager.initialize(tagManagerArgs);

  return(
  <Fragment>
    <Router>
      <Route path="/ass" exact component={Login} />
      <Route path="/representante-medico" exact component={RepresentanteMedico} />
      <Route path="/administrativo" exact component={Estadisticas} />
      <Route path="/resultados" exact component={Resultados} />
      <Route path="/" exact component={ValidacionVistasFirst} />
      <Route path="/cuestionario" exact component={Cuestionario} />
      <Route path="/consultas" exact component={Consultas} />
      <Route path="/graficas" exact component={Graficas} />
      <Route path="/cargando" exact component={Loading} />

     {/*  <Route path="/administradorv" exact component={VistaAdministrador} />
      <Route path="/representv" exact component={VistaRepresentante} /> */}
      


      
    </Router>

  
  </Fragment>
  )


}



export default App;


// Todas las etiquetas deben cerrarse
// Los componentes deben devolver solo 1 elemento padre <padre> </padre> 
//apoyarse de los fragments cuando necesito devolver 2 padres o <> </>
// img siempre se cierra
//las clases de css se pone como ClassName
// el for en jsx es htmlFor

//no se utliza if else while

