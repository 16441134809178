
import React, { useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import '../css/inicio.css'
import { Grid, Container, TextField, Button } from '@material-ui/core';
import constants from './constantes'
import { makeStyles } from '@material-ui/core';
import imagenes from './imagenes';





const useStyles = makeStyles(theme => ({
    root: {
        backgroundImage: `url(${imagenes.fondo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh'
    },
    inputMaterial:{
        width: '100%',
        marginBottom: '20px'
      },

    logocoolor:{
        maxWidth: '300px',
        marginBottom: '20px'
    },

    mensajes:{
        background: '#303F9F',
        padding: '10px 20px',
        color: 'white',
        marginBottom: '30px'
    }
}))

const Login = () => {

    const styles = useStyles()
    const [respuesta, setRespuesta] =   useState({})

    const [credenciales, setCredenenciales] = 
    useState({
    user: "",
    password: "",
    })



    // actualizar state con los inputs para insercion a base de datos
    const handleChange = async event => {
        setCredenenciales({
            ...credenciales,
            [event.target.name]: event.target.value
        })
    }

const  Mensaje = (respuesta) =>{
    console.log( "esta es " + respuesta.messages)
    
}


const handleSubmit =  () =>{
    
        const requestInit ={
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            crendentials: 'includes',
            body: JSON.stringify(credenciales)
        }  

        setTimeout(function(){ 
            return( respuesta.messages)
        }, 3000) 

     fetch(constants.api+'login', requestInit)
      .then(res => res.json())
      .then((res) => {
            console.log(res);
            let utype = res.utype

            let token = res.token
            setRespuesta(res)
            localStorage.setItem('token', token)
            localStorage.setItem('utype', utype)
            let redirect
            switch (utype) {
              case 0:
                  redirect = () => {
                      window.location.href = '/ingresa'
                    }
              
                    redirect()
               break;   
              case 1:
                  redirect = () => {
                      window.location.href = '/ingresa'
                    }
              
                    redirect()

                    break;  
        
                    default:
            
                    break;
          }

      
  

      }).catch(err => {

        console.log(err)
      })
      
  
    }

console.log(respuesta)

    return(

            <Grid  className={styles.root}>
            <Container >
            <div className="row justify-content-center">
            <div className="col-lg-6">
                <div className="caja">

                    <img className={styles.logocoolor} src={imagenes.logocolor} ></img>
                    <h3>Inicio de Sesión</h3><br/>
                    {respuesta.messages  && 
                  <div className={styles.mensajes}>

                     {respuesta.messages}
                
                
                     </div>
           
                 
                }
               
                        <TextField className={styles.inputMaterial} variant="outlined"  onChange={handleChange} name="user" label="Username"/><br/>
                        <TextField  className={styles.inputMaterial} type="password" variant="outlined" onChange={handleChange} name="password" label="Contraseña"/><br/>

                        <Button fullWidth variant="contained" color="primary"  onClick={() => handleSubmit()}>Ingresar</Button>
                    
                </div>
            </div>
            </div>

            </Container>
            </Grid>
      
        )
}



export default Login;