import React, { Fragment, forwardRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../App.css";
import { Button } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Link } from "react-router-dom";
import constants from "../constantes";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import NavigationAdministrador from "../Navigation/NavigationAdministrador";

const VistaRepresentante = () => {
  const [data, setData] = useState([]);

    useEffect( ()=> {
      
    
  
    }, []) 

  console.log(data);

  return (
    <Fragment>
      <NavigationAdministrador></NavigationAdministrador>
      <div className="container ">
        <div className="row  justify-content-center" style={{ marginTop: "50px" }}>
          <div className="col-lg-4">
            <br></br>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              
              startIcon={<PersonAddIcon />}
            >
              <Link to="/registro-administrativo" style={{ color: "white", padding: "10px 0px" }}>
                {" "}
                Registro Administrativo
              </Link>
            </Button>
          </div>

          <div className="col-lg-4">
            <br></br>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: "50px;" }}
              startIcon={<PersonAddIcon />}
            >
              <Link to="/registro-medico" style={{ color: "white", padding: "10px 0px" }}>
                {" "}
                Registro Médico
              </Link>
            </Button>
            <br />
            <br />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VistaRepresentante;
