import logo from '../images/astrazeneca-logo.png';
import fondo from '../images/fondo-aztra.jpg'
import logocolor from '../images/astrazeneca-logo.png'
import salbutamol from '../images/salbultamol.jpg'
import estadisticas from '../images/estadisticas.jpg'
import graficas from '../images/graficas.png'
import consulta from '../images/consulta.png'
import amarillo from '../images/Inhalador.png'
import azul from '../images/Hospital.png'
import verde from '../images/Advertencia.png'
import rojo from '../images/Calavera.png'
import canister from '../images/canisters.png'
import resulta from '../images/resulta.png'



export default {
 "logo": logo,
 "fondo": fondo,
 "logocolor": logocolor,
 "salbutamol": salbutamol,
 "estadisticas": estadisticas,
 "graficas": graficas,
 "consulta": consulta,
 "amarillo": amarillo,
 "azul": azul,
 "verde": verde,
 "rojo": rojo,
 "canister": canister,
 "resulta": resulta
}