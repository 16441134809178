import React, { Fragment, forwardRef, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import "../../App.css";

import NavigationAdministrador from "../Navigation/NavigationAdministrador";
import { TextField, Button } from "@material-ui/core";
import "../../css/custom.css";
import constants from "../constantes";
import estilosForm from "../Formularios/FormulariosStyle";

import imagenes from "../imagenes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Footer from "../Navigation/Footer";




const re = /^[0-9\b]+$/;
ChartJS.register(ArcElement, Tooltip, Legend);





const Graficas = () => {
 
  const [estadisticas, setEstadisticas] = useState({})

  const getestadisticas = async () =>{
    await fetch(constants.api +'salbutamol/dashboard')
      .then(res => res.json())
      .then(res => setEstadisticas(res)).catch(err => {

        console.log(err)
      })
    
  }

  useEffect(() => {
    getestadisticas()
    
  }, []);
  
  

  const {receta, terapia, resultados, kpis } = estadisticas
   
  console.log(receta)
  let recetadata
  let terapiadata
  let resultadosdata
  let kpidata



  recetadata = {
    labels: ['Solo como monoterapia', 'En combinacion con otro ICS', 'En combinacion con otro broncodilatador',],
    datasets: [
      {
        label: 'tipo de receta',
        data: receta,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
         
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        
        ],
        borderWidth: 1,
      },
    ],
  }

  terapiadata = {
    labels: ['Rescate', 'Mantenimiento', 'Ambas'],
    datasets: [
      {
        label: '# of Votes',
        data: terapia,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
         
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
      
        ],
        borderWidth: 1,
      },
    ],
  }

  resultadosdata = {
    labels: ['Verde',  'Amarillo', 'Azul', 'Rojo', ],
    datasets: [
      {
        label: '# of Votes',
        data: resultados,
        backgroundColor: [
          'rgba(57, 197, 57, 1)',
          'rgba(241, 198, 3, 1)',
          'rgba(72, 106, 200, 1)',
          'rgba(255, 0, 0, 1)',
   
        ],
        borderColor: [
          'rgba(57, 197, 57, 1)',
          'rgba(241, 198, 3, 1)',
          'rgba(72, 106, 200, 1)',
          'rgba(255, 0, 0, 1)',
      
        ],
        borderWidth: 1,
      },
    ],
  }

  


  kpidata = {
    labels: ['Landing page', 'Receta', 'Terapia',  'Completado',],
    datasets: [
      {
        label: '# of Votes',
        data: kpis,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
       
        ],
        borderWidth: 1,
      },
    ],
  }



      return (
        <Fragment>
          <NavigationAdministrador></NavigationAdministrador>
          <div className="container ">
            <div className="row justify-content-center">
            
              <div className="col-lg-6"><br></br><br></br>

                <div className="titulo">Tipo receta</div>
                    <Pie data={recetadata} />
              </div>

              <div className="col-lg-6"><br></br><br></br>
              <div className="titulo">Tipo de terapía</div>
                    <Pie data={terapiadata} />
              </div>

                
          
            </div>
            <div className="row justify-content-center">

              <div className="col-lg-6"><br></br><br></br>
              <div className="titulo">Resultados</div>
                     <Pie data={resultadosdata} /> 
              </div>

              <div className="col-lg-6"><br></br><br></br>
              <div className="titulo">KPI</div>
                     <Pie data={kpidata} />
              </div>

                
          
            </div>
          </div>
          <br></br><br></br><br></br>
          <Footer></Footer>
        </Fragment>
      );


};

// Encabezados table

export default Graficas;
