
import React, {Fragment, forwardRef, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import '../../App.css'
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {Modal, TextField, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import constants from '../constantes'

import { Alert } from '@material-ui/lab';



// llamada a iconos de material ui de la datatable
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


// Estilos Modal

 const useStyles = makeStyles((theme) =>({
    modal: {
      position: 'absolute',
      width: '40%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2,4,3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    iconos:{
      cursor: 'pointer'
    },
    inputMaterial:{
      width: '100%'
    }

})) 

const columnas =  [
  {
    title: 'USERNAME',
    field: 'username'
  },

  {
    title: 'NOMBRE',
    field: 'name'
  },

  /* {
    
    title: 'TIPO DE USUARIO',
    field: 'user_type'
    
  } */
 

]


// Encabezados table
const Administrador = () => {

    //estilos
    const styles = useStyles()
    // Muestra los registros de vendedores en la base
    const [data, setData] = useState([])
    // estados para alertas
    const [showSuccessAlert, setshowSuccessAlert] = useState({status: false, messages: ""})
    const [showFailAlert, setshowFailAlert] = useState(false)
    const [modalInsertar, setmodalInsertar] = useState(false)
    const abrirCerrarInsertar =()=>{
      setmodalInsertar(!modalInsertar)
    }

const [modalEditar, setModalEditar] = useState(false)
  const abrirCerrarEditar =()=>{
    setModalEditar(!modalEditar)
}

const [modalEliminar, setModalEliminar] = useState(false)
  const abrirCerrarEliminar =()=>{
    setModalEliminar(!modalEliminar)
}


    const getvendedor = async () =>{
      await fetch(constants.api+'usersadmin')
        .then(res => res.json())
        .then(res => setData(res)).catch(err => {

          console.log(err)
        })
       
    }
    useEffect( ()=> {
      let token = localStorage.getItem('token')
      let utype = localStorage.getItem('utype')
     getvendedor ()
  
    }, [])

    console.log(data)

    const [vendedorSeleccionado, setVendedorSeleccionado]=useState({
      username: "",
      name: "",
      password: "",
      user_type: "",
    })


    const  seleccionarVendedor = (vendedor, caso) =>{
      let vendedornew = {
        user_id: vendedor.user_id,
        username: vendedor.username,
        name: vendedor.name,
        user_type: vendedor.user_type
        
      }
      setVendedorSeleccionado(vendedornew);
      (caso==="Editar")?abrirCerrarEditar()
      :
      abrirCerrarEliminar()
    }




// ------------------------------------------------------------------------------ INSERTAR USUARIO------------------------------------------

// Manejo de estados




// actualizar state con los inputs para insercion a base de datos
const handleChange = event => {
  setVendedorSeleccionado({
      ...vendedorSeleccionado,
      [event.target.name]: event.target.value
  })
  console.log(vendedorSeleccionado)
}


let {username, password, user_type, name } = vendedorSeleccionado

const handleSubmit = () =>{
  user_type = parseInt(user_type, 10)
  //validacion de los datos

  if(username === '' || password === '' || user_type === '' || name === '' ){
    console.log(username)
    console.log(password)
    console.log(user_type)
    console.log(name)
    alert('Todos los campos son obligatorios')
    return
  }else{
    //tipo de data que voy a mandar
        const requestInit ={
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(vendedorSeleccionado)
        }

        console.log(vendedorSeleccionado)
          const insercionuser = async () => {
            await fetch(constants.api+'registro', requestInit)
              .then(res => res.json())
              .then((res) => {
                setData(data.concat(res))
                console.log(res)
                if(res.status === 200){
                  setshowSuccessAlert({
                    status: true, 
                    messages: res.messages
                  })
                  console.log(res.messages)
          
                }else{
                  setshowFailAlert({
                    status: true,
                    messages: res.messages
                  })
                  console.log(res.messages)
                }
              
              } ).catch(err => {

                console.log(err)
              })
          }
     
    insercionuser()
      abrirCerrarInsertar()

      getvendedor()
  
    }

}

const bodyInsertar =(
  <div className={styles.modal} >
    <h3>Rellena los siguientes datos</h3><br/>
    <TextField className={styles.inputMaterial} onChange={handleChange} name="name" label="Nombre"/><br/>
    <TextField className={styles.inputMaterial} onChange={handleChange} name="username" label="Username"/><br/>
    <TextField className={styles.inputMaterial} type="password" onChange={handleChange} name="password" label="Password"/><br/><br/>
    <InputLabel id="demo-simple-select-label">Tipo de Usuario</InputLabel>
    <Select className={styles.inputMaterial}
          name="user_type"
          onChange={handleChange}
    >
          <MenuItem value={1}>Gerente</MenuItem>
          <MenuItem value={3}>VendedorSr</MenuItem>
          <MenuItem value={2}>VendedorJr</MenuItem>
    </Select><br/><br/>
    <div align="right">
    <Button color="primary" onClick={()=>handleSubmit()}>Insertar</Button>
    <Button color="secondary" onClick={()=>abrirCerrarInsertar()}>Cancelar</Button>
    </div>
  </div>
)


//----------------------------------------------------EDITAR USUARIO------------------------------------------------------------------------------


const EditarUser = async () =>{


  if(username === '' || password === '' || user_type === '' || name === '' ){

    alert('Todos los campos son obligatorios')
    return
  }else{
    //tipo de data que voy a mandar
        const requestInit ={
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(vendedorSeleccionado)
        }

        
      console.log(vendedorSeleccionado)

      await fetch(constants.api+'edituser/'+vendedorSeleccionado.user_id, requestInit)
      .then(res => res.json())
      .then((res) =>{
        
        let datanueva = data

        datanueva.map(vendedor=>{
          if(vendedor.user_id === vendedorSeleccionado.user_id){
            vendedor.name = vendedorSeleccionado.name;
            vendedor.username = vendedorSeleccionado.username;
            vendedor.user_type = vendedorSeleccionado.user_type;
          }
        })
        setData(datanueva)
      } 
        
      ).catch(error =>{
        console.log(error)
      })

      abrirCerrarEditar()

      getvendedor ()

    }
}


const bodyEditar =(
  <div className={styles.modal} >
    <h3>Editar Vendedor</h3><br/>
  

    <TextField className={styles.inputMaterial} onChange={handleChange} name="username" label="Username" value={vendedorSeleccionado&&vendedorSeleccionado.username}  /><br/><br/>

    <TextField className={styles.inputMaterial} onChange={handleChange} name="name" label="Nombre" value={vendedorSeleccionado&&vendedorSeleccionado.name} /><br/><br/>

    <InputLabel id="demo-simple-select-label">Tipo de Usuario</InputLabel>
    <Select className={styles.inputMaterial}
          label="Age"
          name="user_type"
          value={vendedorSeleccionado&&vendedorSeleccionado.user_type}
          onChange={handleChange}
        >
          <MenuItem value={1}>Admin</MenuItem>
          <MenuItem value={2}>Vendedor</MenuItem>
    </Select><br/><br/>
    <div align="right">
    <Button color="primary"  onClick={()=>EditarUser()} >Editar</Button>
    <Button color="secondary" onClick={()=>abrirCerrarEditar()}>Cancelar</Button>
    </div>
  </div>
)



//----------------------------------------------------ELIMINAR USUARIO------------------------------------------------------------------------------

const bodyEliminar=(
  <div className={styles.modal}>
    <p>Estás seguro que deseas eliminar al vendedor <b>{vendedorSeleccionado && vendedorSeleccionado.username}</b>? </p>
    <div align="right">
      <Button color="secondary" onClick={()=>peticionDelete()}>Sí</Button>
      <Button onClick={()=>abrirCerrarEliminar()}>No</Button>

    </div>

  </div>
)
const peticionDelete=async()=>{
  const requestInit ={
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'}
  }

  await fetch(constants.api + "deleteuser/" + vendedorSeleccionado.user_id, requestInit)
      .then(res => res.json())
      .then((res) => {
        setData(data.filter(vendedor=>vendedor.user_id!==vendedorSeleccionado.user_id));
      }).catch(error =>{
        console.log(error)
      })

      abrirCerrarEliminar()

      getvendedor()
}


    return(    <Fragment>      
 
  
      <div className="container " >
         
          <div className="row  justify-content-center">
  
            <div className="col-lg-12"><br/><br/><br/>

            <div className="row">
     

              <div className="col-lg-3">
              <br></br><Button variant="contained" fullWidth color="primary" style={{ marginTop: "10px;"}} startIcon={<PersonAddIcon />}   onClick={()=>abrirCerrarInsertar()}>Registro Médico</Button><br/><br/>
              </div>
            </div>

        
        
        { showSuccessAlert.status &&  <Alert onClose={() => setshowSuccessAlert(false)}  >{showSuccessAlert.messages} </Alert>  }
        
        { showFailAlert.status && <Alert onClose={() => setshowFailAlert(false)} severity="error">{showFailAlert.messages}</Alert> }<br/>
  
          
          <MaterialTable 
              icons={tableIcons}
              columns={columnas} 
              data={data} 
              title="Administrador"
             /*  actions = {[
                {
                  icon: Edit,
                  tooltip: 'Editar vendedor',
                  onClick: (event, rowData)=> seleccionarVendedor(rowData, "Editar")
                }, 
                {
                  icon: Delete,
                  tooltip: 'Eliminar vendedor',
                  onClick: (event, rowData)=> seleccionarVendedor(rowData, "Eliminar")
                }
              ]}
  
              options={{
                actionsColumnIndex: -1
              }} */
  
              localization={{
                header:{
                  actions: 'ACCIONES'
                }
              }}
          />
          </div>
          </div>
          </div>
  
                <Modal 
                open={modalInsertar}
                onClose ={abrirCerrarInsertar}>
                      {bodyInsertar}
                </Modal>
  
                <Modal 
                open={modalEditar}
                onClose ={abrirCerrarEditar}>
                      {bodyEditar}
                </Modal>
  
                <Modal 
                open={modalEliminar}
                onClose ={abrirCerrarEliminar}>
                      {bodyEliminar}
                </Modal>
  
    
                
          
   
     </Fragment> 
    )
      
  
  }

  export default Administrador;
