import React from 'react';
import "bootstrap/dist/css/bootstrap.css"
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../../App.css';
import imagenes from '../imagenes';
import PowerSettingsNewIcon  from '@material-ui/icons/PowerSettingsNew';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const NavigationAdministrador = () => {

return(

  <Navbar collapseOnSelect expand="lg"  variant="dark" className="navcolor">
  <Container>
  <Navbar.Brand href="#"><img src={imagenes.logo} width="180px" /></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    {/* <Navbar.Collapse className="justify-content-end">
      <Nav className="me-auto">
      <Nav.Link   className="white" href="/" onClick={()=> localStorage.clear() } >Cerrar Sesión {<PowerSettingsNewIcon />}</Nav.Link>
      </Nav>
    </Navbar.Collapse> */}
  </Container>
</Navbar>
      
)

}

    
 



export default NavigationAdministrador;