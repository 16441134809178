import React, { Fragment, forwardRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../App.css";
import { Button } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Link, Redirect } from "react-router-dom";
import constants from "../constantes";
import NavigationAdministrador from "../Navigation/NavigationAdministrador";
import Login from "../Login";
import Loading from "../Loading"
import VistaAdministrador from "./VistaAdministrador";
import VistaRepresentante from "./VistaRepresentante";
import Cuestionario from "./Cuestionario";
import Estadisticas from "./Estadisticas";




// Encabezados table
const ValidacionVistasFirst = () => {

    // Muestra los registros de vendedores en la base
    const [cotizaciones, setCotizaciones] = useState([])
    const [isLogged, setisLogged] = useState("")
    const [isAdims, setisAdims] = useState("")
    const [data, setData] = useState("")

    const params = new URLSearchParams(window.location.search);

    let tokenurl = params.get("accesstoken")

    console.log(tokenurl)

    useEffect( ()=> {        
      fetch(constants.api+'login/session/?accesstoken=' + tokenurl , {
        credentials: 'include',
        headers: new Headers({
        'Content-Type': 'application/json'
       })})
        .then(res => res.json())
        .then(res => {          
          if(res){
            console.log(res)
            setisLogged(res.isLogged)
            setisAdims(res.isAdmin)

            setData(res)
          }else{
             console.log("no respondio el servicio de aztra")
          } 
        }).catch(err => {
          //throw new Error(err)
          console.log(err)
        })
       

    }, [])

 console.log(isLogged)
 console.log(isAdims)


let type_user
let username
let middle
let firstN
let lastN
let substr2 
if(data){
//type_user = data.user._json.groups[0]

  middle = data.middleName ? data.middleName : " "

  firstN = data.firstName ? data.firstName : " "

  lastN =  data.lastName ? data.lastName : " "

 username= firstN + " " + middle + " " + lastN

 substr2 = data.sub
 
}else{
  console.log("esperando respuesta")
}

console.log(substr2)

//console.log( JSON.stringify( "Este es la lectura de isLogged: " + obj.isLogged))
//console.log( JSON.stringify(type_user))
localStorage.setItem('username', username)
localStorage.setItem('sub', substr2)

console.log(username)



  switch (isLogged) {
  case true:
    if(isAdims){
    return(
      <Estadisticas></Estadisticas>
    )
  }else{
    return(
      <Cuestionario></Cuestionario>
      
    )
  }
    break;

    case false:
     
     let redirect = () => {
        window.location.href = constants.api + '/login'
      }   

      redirect() 

      const requestInit ={
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        crendentials: 'includes',
        body: JSON.stringify(tokenurl)
    }  


 fetch(constants.api+'login', requestInit)
  .then(res => res.json())
  .then((res) => {
        console.log(res);
  

  }).catch(err => {

    console.log(err)
  })
    
    break;
  default:    
  return(      
      <Loading></Loading>     
  )
    break;
}









    
  }

  export default ValidacionVistasFirst;