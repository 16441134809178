import React, { Fragment, forwardRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../App.css";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Modal, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import { Link } from "react-router-dom";

import constants from "../constantes";

import { Alert } from "@material-ui/lab";
import NavigationAdministrador from "../Navigation/NavigationAdministrador";
import Footer from "../Navigation/Footer";

// llamada a iconos de material ui de la datatable
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// Estilos Modal

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  iconos: {
    cursor: "pointer",
  },
  inputMaterial: {
    width: "100%",
  },
}));

const columnas = [
  {
    title: "Consulta",
    field: "folio",
  },

  {
    title: "Usuario",
    field: "usuario",
  },

  {
    title: "Fecha",
    field: "fecha",
    type: "date"
  },

  {
    title: "Prescripción",
    field: "prescripcion",
  },
  {
    title: "Inhalaciones",
    field: "inalaciones",
  },
  {
    title: "frecuencia",
    field: "frecuencia",
  },

  {
    title: "Periodo",
    field: "tipo_de_periodo",
  },

  /* {
    
    title: 'TIPO DE USUARIO',
    field: 'user_type'
    
  } */
];

// Encabezados table
const Consultas = () => {
  //estilos
  const styles = useStyles();
  // Muestra los registros de vendedores en la base
  const [data, setData] = useState([]);
  // estados para alertas
  const [showSuccessAlert, setshowSuccessAlert] = useState({
    status: false,
    messages: "",
  });
  const [showFailAlert, setshowFailAlert] = useState(false);
  const [modalInsertar, setmodalInsertar] = useState(false);
  const abrirCerrarInsertar = () => {
    setmodalInsertar(!modalInsertar);
  };

  const [modalEditar, setModalEditar] = useState(false);
  const abrirCerrarEditar = () => {
    setModalEditar(!modalEditar);
  };

  const [modalEliminar, setModalEliminar] = useState(false);
  const abrirCerrarEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const getinfo = async () => {
    await fetch(constants.api + "salbutamol/terapias")
      .then((res) => res.json())
      .then((res) => setData(res)).catch(err => {

        console.log(err)
      });
  };
  useEffect(() => {

    getinfo();
  }, []);

  console.log(data);

  const [vendedorSeleccionado, setVendedorSeleccionado] = useState({
    username: "",
    name: "",
    password: "",
    user_type: "",
  });



  let { username, password, user_type, name } = vendedorSeleccionado;

  const handleSubmit = () => {


      //tipo de data que voy a mandar
      const requestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(vendedorSeleccionado),
    }
  };






  return (
    <Fragment>
      <NavigationAdministrador></NavigationAdministrador>
      <div className="container ">
        <div className="row  justify-content-center">
          <div className="col-lg-12">
            <br />
            <br />
            <br />
            <br />

            <MaterialTable
              icons={tableIcons}
              columns={columnas}
              data={data}
              className="selectstyle"
              title="Representantes Médicos"
              /*  actions = {[
                {
                  icon: Edit,
                  tooltip: 'Editar vendedor',
                  onClick: (event, rowData)=> seleccionarVendedor(rowData, "Editar")
                }, 
                {
                  icon: Delete,
                  tooltip: 'Eliminar vendedor',
                  onClick: (event, rowData)=> seleccionarVendedor(rowData, "Eliminar")
                }
              ]}
  
              options={{
                actionsColumnIndex: -1
              }} */

              localization={{
                header: {
                  actions: "ACCIONES",
                },
              }}
            />
          </div>
        </div>
      </div>
    
      <br></br>
      <br></br>
      <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
     <Footer></Footer> 

    </Fragment>
  );
};

export default Consultas;
