import React, { Fragment, forwardRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../App.css";
import { Button } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Link } from "react-router-dom";
import constants from "../constantes";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import NavigationAdministrador from "../Navigation/NavigationAdministrador";
import { object } from "yup";
import VistaRepresentante from "./VistaRepresentante";

const VistaAdministrador = () => {
  const [data, setData] = useState("");
  const [isLogged, setisLogged] = useState("")


    useEffect( ()=> {
       fetch(constants.api+'login/session', {
        headers: {
          "Content-Type": "application/json"
        },
        credentials: 'include'
      })
      .then(res => res.json())
      .then(res => {          
        if(res){
          console.log(res)
          setisLogged(res.isLogged)
          setData(res)
        }else{
           console.log("no respondio el servicio de aztra")
        } 

      }).catch(err => {

        console.log(err)
      })
    
    }, []) 

    const obj = {
      "isLogged": true,
      "user": {
          "sub": "J_lGAG4GJzlDcF6pa1JSPKfLgu9uOtH0kJpz7Ls4fjE",
          "oid": "509f37d4-0503-4c43-b416-40219aa01527",
          "displayName": "DCR Mexico",
          "name": {},
          "_raw": "{\"aud\":\"f27df581-0f49-4bfb-b4e3-f5cc31a7c66e\",\"iss\":\"https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314/v2.0\",\"iat\":1648275428,\"nbf\":1648275428,\"exp\":1648279328,\"groups\":[\"XAZ-MX-Alta Medicos AZ- Admin\"],\"name\":\"DCR Mexico\",\"nonce\":\"tv9iyLT_ty9dR-mxH-IWxbNDyVAF83Gj\",\"oid\":\"509f37d4-0503-4c43-b416-40219aa01527\",\"preferred_username\":\"kfxh461@astrazeneca.net\",\"rh\":\"0.AQUAo4mOr6zZL0KtBsxOtCFDFIH1ffJJD_tLtOP1zDGnxm4FAOM.\",\"sub\":\"J_lGAG4GJzlDcF6pa1JSPKfLgu9uOtH0kJpz7Ls4fjE\",\"tid\":\"af8e89a3-d9ac-422f-ad06-cc4eb4214314\",\"uti\":\"jw_KBtzQ0k-Lgh-05ZJSAA\",\"ver\":\"2.0\"}",
          "_json": {
              "aud": "f27df581-0f49-4bfb-b4e3-f5cc31a7c66e",
              "iss": "https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314/v2.0",
              "iat": 1648275428,
              "nbf": 1648275428,
              "exp": 1648279328,
              "groups": [
                  "XAZ-MX-Alta Medicos AZ- Admin"
              ],
              "name": "DCR Mexico",
              "nonce": "tv9iyLT_ty9dR-mxH-IWxbNDyVAF83Gj",
              "oid": "509f37d4-0503-4c43-b416-40219aa01527",
              "preferred_username": "kfxh461@astrazeneca.net",
              "rh": "0.AQUAo4mOr6zZL0KtBsxOtCFDFIH1ffJJD_tLtOP1zDGnxm4FAOM.",
              "sub": "J_lGAG4GJzlDcF6pa1JSPKfLgu9uOtH0kJpz7Ls4fjE",
              "tid": "af8e89a3-d9ac-422f-ad06-cc4eb4214314",
              "uti": "jw_KBtzQ0k-Lgh-05ZJSAA",
              "ver": "2.0"
          }
      }
    } 
    
    let type_user
    let username
    if(data){
    type_user = data.user._json.groups[0]
     username= data.user.displayName
    }else{
      console.log("esperando respuesta")
    }

    if(isLogged && type_user == "XAZ-MX-Alta Medicos AZ- Admin"){
      return (
        <Fragment>
          <NavigationAdministrador></NavigationAdministrador>
          <div className="container ">
            <div className="row  justify-content-center" style={{ marginTop: "50px" }}>
              <div className="col-lg-4">
                <br></br>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  
                  startIcon={<PersonAddIcon />}
                >
                  <Link to="/registro-administrativo" style={{ color: "white", padding: "10px 0px" }}>
                    {" "}
                    Registro Administrativo
                  </Link>
                </Button>
              </div>
    
              <div className="col-lg-4">
                <br></br>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: "50px;" }}
                  startIcon={<PersonAddIcon />}
                >
                  <Link to="/registro-medico" style={{ color: "white", padding: "10px 0px" }}>
                    {" "}
                    Registro Médico
                  </Link>
                </Button>
                <br />
                <br />
              </div>
    
            <div className="col-lg-4">
                <br></br>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: "50px;" }}
                  startIcon={<SimCardDownloadIcon />}
                  
                >
                  {/* <a href="http://localhost:5010/clues/az/getlayout" style={{ color: "white", padding: "10px 0px" }}>
                    Exportar base de datos
                  </a> */}
    
                  <Link to="/export-catalogo" style={{ color: "white", padding: "10px 0px" }}>
                    {" "}
                    Exportar base de datos
                  </Link>
                 
                </Button>
                <br />
                <br />
              </div> 
            </div>
          </div>
        </Fragment>
      );
    }else{
      return(
      <VistaRepresentante></VistaRepresentante>
      )
    }

};

export default VistaAdministrador;
