import React from 'react';
import "bootstrap/dist/css/bootstrap.css"
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../../App.css';
import imagenes from '../imagenes';
import PowerSettingsNewIcon  from '@material-ui/icons/PowerSettingsNew';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';


const NavigationAdministrador = () => {
  let username = localStorage.getItem('username')

return(

  <Navbar collapseOnSelect expand="lg"  variant="white" className="navcolor">
  <Container>
  <Navbar.Brand href="/"><img src={imagenes.logo} width="180px" /></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
     <Navbar.Collapse className="justify-content-end">
      <Nav className="me-auto">
      <Nav.Link   className="white" onClick={()=> localStorage.clear() } ><PersonIcon /> {username} </Nav.Link>
      </Nav>
    </Navbar.Collapse> 
  </Container>
</Navbar>


      
)

}

    
 



export default NavigationAdministrador;